import logo from "./logo.svg"

function App() {
	const tokenCA = ""
	return (
		//yellow background with text in the center
		<div className="font-ducky">
			<header className="sticky top-0 z-50 bg-green-600">
				<nav className="flex justify-between items-center p-4">
					<a href="https://www.pump.fun/epWq5V6eAmJFtGKEoEVbTBcxMqbpv1xc16pwXrEprvM" target="_blank" rel="noreferrer">
					</a>

					<ul className="flex space-x-4">
						{/* <a href="#tokenomics">
							<li>TOKENOMICS</li>
						</a> */}
						{/* <a href="#roadmap">
							<li>ROADMAP</li>
						</a>
						<a href="#about">
							<li>ABOUT</li>
						</a> */}
					</ul>
				</nav>
			</header>
			<div className="  font-bold font-ducky min-w-screen min-h-screen">
				{/* sticky header */}

				<body className="flex flex-col font-bold font-ducky p-12 bg-green-600">
					<h1 className="text-lg md:text-9xl text-black-700">$Bullest</h1>
					<p>brainlet likes the green chart</p>
					<br />
					<div className="flex justify-center">
						<div class="container mx-auto px-5 py-10">
							<div class="text-center md:text-4xl text-sm outline-3">
								<p class="p-3">
									CA:
								</p>
							</div>
							{/* <p className="text-4xl text-black text-center mt-12">
										BUY <p className="text-yellow-600 text-6xl font-bold">$</p> NOW !
									</p> */}
							<div className="mx-auto w-full flex items-center justify-center">
								<a href="https://www.bullest.xyz/" target="_blank" rel="noreferrer">
									[Website]       |
								</a>
								<a href="https://t.me/bullestonsol" target="_blank" rel="noreferrer">
									[Telegram]       |
								</a>
								<a href="https://twitter.com/bullestonsol" target="_blank" rel="noreferrer">
									[Twitter]
								</a>
							</div>
							{/* <div class="rounded-fulltext-center md:text-4xl text-sm outline-3 mt-12 flex justify-around p-5 ">
								<a href="https://solscan.io/token/" target="_blank" rel="noreferrer">
									<img src="/dextools.png" alt="solana" className="w-24 p-3 rounded-full items-center justify-center flex self-center bg-black ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300" />
								</a>
								<a href="https://t.me/supwif" target="_blank" rel="noreferrer">
									<img src="/telegram.png" alt="solana" className="w-24 p-3 rounded-full items-center justify-center flex self-center bg-black ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300" />
								</a>
								<a href="https://twitter.com/SupWif" target="_blank" rel="noreferrer">
									<img src="/x.png" alt="solana" className="w-24 p-3 rounded-full items-center justify-center flex self-center bg-black ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300" />
								</a>
								<a href="https://solscan.io/token/" target="_blank" rel="noreferrer">
									<img src="/dex-screener.png" alt="solana" className="w-24 p-3 rounded-full items-center justify-center flex self-center bg-black ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300" />
								</a>
							</div> */}
							<img src="/main.png" alt="" className="w-1/2  self-center flex mx-auto mt-5" />

						</div>
					</div>
					{/* <div id="tokenomics">
						<h1 className="text-6xl text-black text-center mt-20">TOKENOMICS</h1>
						<div className="text-4xl text-black text-center flex justify-around mt-5">
							<br />
							<div className="bg-orange-300 text-black text-center text-2xl w-48 p-5 mt-auto mb-auto rounded-lg shadow-orange-700 shadow-lg">TAX BUY / SELL : 0%</div>
							<br />
							<div className="bg-orange-300 text-black text-center text-2xl w-48 p-5 mt-auto mb-auto rounded-lg shadow-orange-700 shadow-lg">Liquidity Pool Burnt</div>
							<br />
							<div className="bg-orange-300 text-black text-center text-2xl w-48 p-5 mt-auto mb-auto rounded-lg shadow-orange-700 shadow-lg">Mint Revoked</div>
							<br />
						</div>
					</div> */}
					<div id="roadmap" className="flex justify-center mt-32">
						<div>
							<br />
							{/* <h1 className="text-6xl text-black text-center">ROADMAP</h1> */}
							{/* roadmap in box with text and arrow pointing to the right */}
							{/* <div className="flex items-center justify-between w-full gap-x-11">
								<div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-4">
									<div>
										<h2 className="text-gray-800 text-4xl font-semibold">Q1</h2>
										<p className="mt-2 text-gray-600 text-2xl">
											- Launch on PUMP.FUN
											<br />
											- Community Building
											<br />
											- King of the Hill
											<br />- List on Raydium
										</p>
									</div>
								</div>
								<div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-4">
									<div>
										<h2 className="text-gray-800 text-3xl font-semibold">Q2</h2>
										<p className="mt-2 text-gray-600 text-2xl">
											- Burn 32% of the supply
											<br />
											- Listing on CoinMarketCap
											<br />
											- Listing on CoinGecko
											<br />
										</p>
									</div>
								</div>
								<div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-4">
									<div>
										<h2 className="text-gray-800 text-3xl font-semibold">Q3</h2>
										<p className="mt-2 text-gray-600 text-2xl">
											- NFT Marketplace
											<br />
											- Launch on Multiple CEX platforms
											<br />
										</p>
									</div>
								</div> 
							</div>*/}
						</div>
					</div>
					<div>
						<br />
						{/* <h1 className="text-6xl text-black text-center mt-24 text-nowrap">
							What is<p className="text-yellow-600 "></p> about?
						</h1>
						<div className="flex justify-center text-slate-600 py-4 px-8 bg-white shadow-lg rounded-lg  mt-12">
							
						</div> */}
					</div>
				</body>
			</div>
		</div>
	)
}

export default App
